import React from "react";

import { Layout } from "../../layout";

import { SectionSlider } from "./SectionSlider";
import { SectionServicios } from "./SectionServicios";
import { SectionNuestrosClientes } from "./SectionNuestrosClientes";
import { SectionContacts } from "./SectionContactos";

function IndexPage(props){

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Mesa de Servicios IT - Inicio";
    }, []);

    return(
        <Layout>
            <SectionSlider />
            <SectionServicios />
            <SectionNuestrosClientes />
            <SectionContacts />
        </Layout>
    );

}

export { IndexPage };