import React from "react";

import { Layout } from "../../layout";

function DatosBancariosPage(props){

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Mesa de Servicios IT - Datos Bancarios";
    }, []);

    return(
        <Layout>
            <div className="container" style={{padding: '12px'}}>
                <div style={{width: '100%', marginTop: '32px', marginBottom: '32px'}} className="has-text-centered">
                    <span className="title">Datos Bancarios</span>
                </div>
                <div className="box">
                    <strong>Razón Social: </strong>MS IT SAS<br />
                    <strong>CUIT: </strong>30717168999 <span className="is-size-7 has-text-weight-bold" style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText("30717168999")}>(Copiar)</span>
                </div>
                <div className="columns" style={{marginBottom: '32px'}}>
                    <div className="column">
                        <div className="box has-text-centered" style={{paddingBottom: '64px'}}>
                            <div className="client hsbc"></div><br />
                            <strong>Banco: </strong>HSBC Argentina<br />
                            <strong>Alias: </strong>msithsbc <span className="is-size-7 has-text-weight-bold" style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText("msithsbc")}>(Copiar)</span><br />
                            <strong>CBU: </strong>1500005300005534149850 <span className="is-size-7 has-text-weight-bold" style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText("1500005300005534149850")}>(Copiar)</span><br />
                        </div>
                    </div>
                    <div className="column">
                        <div className="box has-text-centered" style={{paddingBottom: '64px'}}>
                            <div className="client macro"></div><br />
                            <strong>Banco: </strong>Banco Macro<br />
                            <strong>Alias: </strong>msitmacro <span className="is-size-7 has-text-weight-bold" style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText("msitmacro")}>(Copiar)</span><br />
                            <strong>CBU: </strong>2850331630094198383541 <span className="is-size-7 has-text-weight-bold" style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText("2850331630094198383541")}>(Copiar)</span><br />
                        </div>    
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export { DatosBancariosPage };