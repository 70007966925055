import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { IndexPage } from "./pages/Index";
import { ContactoPage } from "./pages/Contacto";
import { DatosBancariosPage }  from "./pages/DatosBancarios";
import { DescargasPage } from "./pages/Descargas";
import { Page404 } from "./pages/Page404";
import { ServiciosPage } from "./pages/Servicios";

import './App.css';
// CSS
import 'bulma/css/bulma.min.css';

function App() {
    return(
        <div className="root">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="/contact" element={<ContactoPage />} />
                    <Route path="/services" element={<ServiciosPage />} />
                    <Route path="/downloads" element={<DescargasPage />} />
                    <Route path="/bankdata" element={<DatosBancariosPage />} />
                    <Route path="/404" element={<Page404 />} />
                    <Route path="/*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;