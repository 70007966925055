import React from "react";

import { Link } from "react-router-dom";

function SectionContacts(props){
    return(
        <section className='contacto'>
            <div className='container has-text-centered' style={{padding: '48px'}}>
                <span className='title has-text-white'>Encuentra la mejor solución para tu empresa</span>
                <br /><br />
                <span className='subtitle has-text-white'>Contacta con un asesor para poder resolver tu problema.</span>
                <br /><br />
                <Link class="button is-large" to={'/contact'}>
                    <span>Contactanos</span>
                    <span class="icon is-right">
                        <i class="fa-solid fa-arrow-right"></i>
                    </span>
                </Link>
            </div>
        </section>
    );
}

export { SectionContacts };