import React from "react";

function SectionNuestrosClientes(props){
    return(
        <section className='nuestros-clientes'>
            <div className='container has-text-centered' style={{padding: '48px'}} id="ourClients">
                <span className='title'>Empresas que dia a día confían en nuestros servicios</span>
                <br /><br />
                <div className='client autocity' />
                <div className='client grupotagle' />
                <div className='client atacc' />
                <div className='client cab' />
                <div className='client dulcor' />
                <div className='client orieta' />
                <div className='client yuspe' />
                <div className='client vanoli' />
                <div className='client veneziana' />
                <div className='client tomaselli' />

                
                <div className='client pellisa' />
                <div className='client grupoares' />
                <div className='client maretich' />
                <div className='client femmi' />
                
                
                
                
                
                
                <div className='client papeleracumbre' />
                <div className='client ric' />
                <div className='client vientri' />
                
                
                <div className='client estin' />
                
                
                
            </div>
        </section>
    );
}

export { SectionNuestrosClientes };