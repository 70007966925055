import React from "react";

import { Link } from "react-router-dom";

function SectionServicios(props){
    return(
        <React.Fragment>
            <section className='contacto'>

            
            <div className='container has-text-centered'>

            <span className='title has-text-white'>Nuestros servicios</span>
            <br /><br />
            <span className='subtitle has-text-white'>Estos son los servicios con los que podemos ayudarte. Presiona en "ver más" para saber más detalles.</span>
            <br /><br /><br />

            <div className="columns has-text-centered has-text-white">

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
        <i className="fa-solid fa-globe"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Soporte Remoto</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-laptop"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Reparación y venta de equipos</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-calendar-check"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Servicio Mensual</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-server"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Configuración de Servidores</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-network-wired"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Obras de Infraestructura de Red</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-arrows-to-circle"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Soluciones para eventos temporales</div>
</div>

<div className="column">
    <div className="icon" style={{fontSize: '80px', marginTop: '32px'}}>
    <i className="fa-solid fa-recycle"></i>
    </div>
    <div className="is-size-5 has-text-weight-bold" style={{marginTop: '12px'}}>Recupero de tecnología y reciclaje</div>
</div>

</div>

                <Link class="button is-large" to={'/services'}>
                    <span>Ver más</span>
                    <span class="icon is-right">
                        <i class="fa-solid fa-arrow-right"></i>
                    </span>
                </Link>
            </div>
        </section>

        </React.Fragment>
    );
}

export { SectionServicios };