import React from "react";

import { Link } from "react-router-dom";

function NavBar(props){

    const [isMenuActive, setIsMenuActive] = React.useState(false);

    return(
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="https://mesadeserviciosit.com">
                    <div className='logo-msit' />
                    <div className='isotipo'>Mesa de Servicios IT</div>
                </a>
                <a role="button" className={isMenuActive ? "navbar-burger is-active" : "navbar-burger"} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={() => setIsMenuActive(!isMenuActive)}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                <div className='whatsapp-mobile is-hidden-desktop' style={{cursor:'pointer', position: 'absolute', left: "100%", height: '52px', width: '52px', textAlign: 'center', marginLeft: '-96px', padding: '13px'}} onClick={() => window.open("https://wa.me/5493518642331?text=Buenos%20d%C3%ADas%21%20Estoy%20interesado%20en%20sus%20servicios.")}>
                    <span class="icon" style={{fontSize: '24px'}}>
                        <i class="fa-brands fa-whatsapp has-text-success"></i>
                    </span>
                </div>
            </div>
            <div id="navbarBasicExample" className={isMenuActive ? "navbar-menu is-active" : "navbar-menu"}>
                <div className="navbar-start">
                    <Link className="navbar-item" to={'/'}>
                        <div className='text-btn'>Inicio</div>
                    </Link>
                    <Link className="navbar-item" to={'/services'}>
                        <div className='text-btn'>Servicios</div>
                    </Link>
                    <a className="navbar-item" href='/#ourClients'>
                        <div className='text-btn'>Clientes</div>
                    </a>
                    <Link className="navbar-item" to={'/contact'}>
                        <div className='text-btn'>Contacto</div>
                    </Link>
                    {/* <div className="navbar-item has-dropdown is-hoverable">
                    <a className="navbar-link">
                        More
                    </a>
                    <div className="navbar-dropdown">
                        <a className="navbar-item">
                        About
                        </a>
                        <a className="navbar-item is-selected">
                        Jobs
                        </a>
                        <a className="navbar-item">
                        Contact
                        </a>
                        <hr className="navbar-divider" />
                        <a className="navbar-item">
                        Report an issue
                        </a>
                    </div>
                    </div> */}
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            {/* <a className="button is-primary">
                            <strong>Sign up</strong>
                            </a> */}
                            {/* <a className="button is-light">
                            Log in
                            </a> */}
                            <div style={{fontWeight: 'bold', cursor: 'pointer'}} onClick={() => window.open("https://wa.me/5493518642331?text=Buenos%20d%C3%ADas%21%20Estoy%20interesado%20en%20sus%20servicios.")} >+54 351 8642331</div>
                            <div className='whatsapp-btn-desktop' style={{transform: 'scale(170%)', marginRight: '8px', cursor: 'pointer'}} onClick={() => window.open("https://wa.me/5493518642331?text=Buenos%20d%C3%ADas%21%20Estoy%20interesado%20en%20sus%20servicios.")}>
                                <span class="icon">
                                    <i class="fa-brands fa-whatsapp has-text-success"></i>
                                </span>
                            </div>
                            <div className='login-btn' onClick={() => window.open("https://soporte.mesadeserviciosit.com")}>
                                Mi cuenta
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export { NavBar };