import React from "react";

import { Layout } from "../../layout";

function ServiciosPage(props){

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Mesa de Servicios IT - Servicios";
    }, []);

    return (
        <Layout>
            <section>
                <div className="container" style={{marginTop: '32px', marginBottom: '32px', padding: '12px'}}>
                    <div className="has-text-centered" style={{width: '100%'}}>
                        <span className="title">
                            Nuestros Servicios
                        </span>
                    </div>
                    <div className="columns" style={{marginTop: '32px'}}>
                        <div className="column">

                             <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-recycle"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Recupero de tecnología y reciclaje</span>
                                <br />
                                - Extender la vida útil del equipamiento<br />
                                - Aquellos elementos que no pueden ser reutilizados garantizamos que se convertiran en material para volver a circular y no contaminar<br />
                                <br />
                                <a style={{textDecoration: 'underline', cursor: 'pointer'}} href="%PUBLIC_URL%/INFORME_AUTOCITY.pdf">Descarga informe de recupero para Autocity</a>
                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-globe"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Soporte Remoto</span>
                                <br />
                                - Resolución de incidentes y requerimientos de manera remota<br />
                                - Sistema de Tickets propio para una mejor comunicación con el usuario<br />
                                - Optimización del sistema, instalación de programas, configuración de impresoras, etc.<br />
                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-laptop"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Reparación y venta de equipos</span>
                                <br />
                                - Reparación de computadoras de escritorio y notebooks<br />
                                - Instalación de sistema operativo y software de uso de oficina y especializado<br />
                                - Limpieza de hardware (física) y de software (lógica)<br />
                                - Venta de equipos reacondicionados y nuevos<br />

                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-calendar-check"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Servicio Mensual</span>
                                <br />
                                - Atención a usuarios durante horario laboral y emergencias<br />
                                - Administración de infraestructura de red y equipos de usuarios finales<br />
                                - Inventario y mantenimiento de equipamiento<br />
                                - Administración de servidores y servicios de la empresa<br />
                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-server"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Configuración de Servidores</span>
                                <br />
                                - Implementación, reparación y configuración de servidores<br />
                                - Preparación de ambientes de virtualización y sus máquinas virtuales<br />
                                - Planeamiento e implementación de métodos de resguardo de datos (backups)<br />
                                - Configuración de accesos (usuarios, VPN, seguridad, etc)<br />
                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-network-wired"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Obras de Infraestructura de Red</span>
                                <br />
                                - Planeamiento e implementación de redes nuevas, cableadas o inalámbricas<br />
                                - Análisis y mejoras a redes existentes<br />
                                - Informes, documentación y normalización de redes<br />
                            </div>

                            <div className="box">
                                <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-arrows-to-circle"></i>
                                    </span>
                                <span className="is-size-5 has-text-weight-bold">&nbsp;Soluciones para eventos temporales</span>
                                <br />
                                - Implementación de conectividad en áreas remotas o sin internet<br />
                                - Servicio de guardia y asistencia a los usuarios<br />
                                - Configuración de redes internas, equipamiento, impresoras, etc<br />
                                - Alquiler de equipamiento de red y computadoras para usuarios finales<br />
                            </div>

                           

                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export { ServiciosPage };