import React from "react";

import { Layout } from "../../layout";

function ContactoPage(props){

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Mesa de Servicios IT - Contacto";
    }, []);

    return(
        <Layout>
            <section>
                <div className="container" style={{marginTop: '32px', marginBottom: '32px', padding: '12px'}}>
                    <div className="has-text-centered" style={{width: '100%'}}>
                        <span className="title">
                            ¿Necesitas contactarnos?
                        </span>
                    </div>
                    <div className="columns" style={{marginTop: '32px'}}>
                        <div className="column">
                            <div className="box">
                                <span className="is-size-5 has-text-weight-bold">Whatsapp</span>
                                <br />
                                Puedes escribirnos por WhatsApp y te responderemos a la brevedad
                                <br />
                                <a className="button" style={{marginTop: '16px'}} href="https://wa.me/5493518642331?text=Buenos%20d%C3%ADas%21%20Estoy%20interesado%20en%20sus%20servicios." target="_blank">
                                    <span>
                                        Enviar Whatsapp
                                    </span>
                                    <span className="icon has-text-primary" style={{fontSize: '24px'}}>
                                        <i className="fa-brands fa-whatsapp"></i>
                                    </span>
                                </a>
                            </div>
                            {/* <div className="box">
                                <span className="is-size-5 has-text-weight-bold">Formulario de Contacto</span>
                                <br />
                                También puedes rellenar el siguiente formulario y nos contactaremos con usted
                                <br />
                                <button className="button" style={{marginTop: '16px'}}>
                                    <span>
                                        Rellenar Formulario
                                    </span>
                                    <span className="icon has-text-link" style={{fontSize: '24px'}}>
                                        <i className="fa-regular fa-pen-to-square"></i>
                                    </span>
                                </button>
                            </div> */}
                            <div className="box">
                                <span className="is-size-5 has-text-weight-bold">E-Mail</span>
                                <br />
                                    Envíanos un correo a <strong><a href="mailto:contacto@mesadeserviciosit.com">contacto@mesadeserviciosit.com</a></strong>
                                <br />
                                <a className="button" style={{marginTop: '16px'}}  href="mailto:contacto@mesadeserviciosit.com">
                                    <span>
                                        Enviar Correo
                                    </span>
                                    <span className="icon has-text-link" style={{fontSize: '24px'}}>
                                        <i className="fa-regular fa-envelope"></i>
                                    </span>
                                </a>
                            </div>
                            <div className="box">
                                <span className="is-size-5 has-text-weight-bold">Envíanos un Ticket</span>
                                <br />
                                    Si ya estás registrado puedes enviarnos un ticket desde nuestra plataforma.<br />
                                    ¿No tienes usuario? Escribenos para registrarte!
                                <br />
                                <a className="button" style={{marginTop: '16px'}}  href="https://soporte.mesadeserviciosit.com" target="_blank">
                                    <span>
                                        Ir al Sistema
                                    </span>
                                    <span className="icon has-text-link" style={{fontSize: '24px'}}>
                                        <i className="fa-solid fa-ticket"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="column" style={{fontSize: '18px'}}>
                            <span className="is-size-5 has-text-weight-bold">Dónde encontrarnos</span>
                            <br />
                            <span className="icon" style={{fontSize: '18px', color: '#212121'}}>
                                <i className="fa-solid fa-location-dot"></i>
                            </span>
                            <span>
                                &nbsp;<strong>Dirección: </strong> Haedo 858, Córdoba, Córdoba, Argentina
                            </span>
                            <br />
                            <span className="icon" style={{fontSize: '18px', color: '#212121'}}>
                                <i className="fa-solid fa-phone"></i>
                            </span>
                            <span>
                                &nbsp;<strong>Teléfono: </strong> +543518642331
                            </span>
                            <br />
                            <span className="icon" style={{fontSize: '18px', color: '#212121'}}>
                                <i className="fa-solid fa-envelope"></i>
                            </span>
                            <span>
                                &nbsp;<strong>Correo: </strong> contacto@mesadeserviciosit.com
                            </span>
                            <br />
                            <br />
                            <div style={{height: '400px', width: '100%'}}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3404.8358827010106!2d-64.2145156!3d-31.4186475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9432989e218f4519%3A0xcd724de17a4920d!2sHaedo%20858%2C%20X5010AKJ%2C%20C%C3%B3rdoba!5e0!3m2!1ses!2sar!4v1717806789420!5m2!1ses!2sar" width="100%" height="400" frameborder="0" style={{border: 0}} allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export { ContactoPage };