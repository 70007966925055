import React from "react";

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import slider1 from "./img/slider1.webp";
import slider2 from "./img/slider2.webp";
import slider3 from "./img/slider3.webp";
import slider4 from "./img/slider4.webp";
import slider5 from "./img/slider5.webp";

function SectionSlider(props){

    const images = [
        // "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        // "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        // "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
        slider1,
        slider2,
        slider3,
        slider4,
        slider5,
    ];

    return(
        <React.Fragment>
            <div style={{marginTop: '-24px'}} className='is-hidden-tablet'></div>
            <Slide
                autoplay={true}
                canSwipe={true}
                duration={6000}
                infinite={true}
                transitionDuration={450}
                cssClass='is-hidden-mobile'
            >
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                        <div className='container' style={{width: '100%', height: "100%", padding: "120px 48px", color: '#FFF'}}>
                            <div className='columns'>
                                <div className='column'style={{color: "#FFF", textShadow: '1px 1px 3px black'}}>
                                    <span className='title' style={{color: "#FFF"}}>
                                        Servicios Informáticos
                                    </span>
                                    <br /><br />
                                    <span>
                                        Nos dedicamos a brindar una mesa de servicios para empresas e individuos para solucionar sus problemas con equipos, redes, servidores o servicios.
                                    </span> 
                                    <br /><br />
                                    <span>
                                    Contactános por Whatsapp o nuestro formulario de contacto.
                                    </span> 
                                </div>
                                <div className='column'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                        <div className='container' style={{width: '100%', height: "100%", padding: "120px 48px", color: '#FFF'}}>
                            <div className='columns'>
                                <div className='column'>
                                </div>
                                <div className='column has-text-right'style={{color: "#FFF", textShadow: '1px 1px 3px black'}}>
                                    <span className='title' style={{color: "#FFF"}}>
                                        Reparación de equipos
                                    </span>
                                    <br /><br />
                                    <span>
                                        Contamos con un taller de reparaciones y técnicos capacitados para armar, reparar, limpiar y configurar equipos informáticos de usuarios finales, redes y servidores.
                                    </span> 
                                    <br /><br />
                                    <span>
                                    Contactános por Whatsapp o nuestro formulario de contacto.
                                    </span> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                        <div className='container' style={{width: '100%', height: "100%", padding: "120px 48px", color: '#FFF'}}>
                            <div className='columns'>
                                <div className='column'style={{color: "#FFF", textShadow: '1px 1px 3px black'}}>
                                    <span className='title has-text-white'>
                                        Recupero de tecnología y reciclaje
                                    </span>
                                    <br /><br />
                                    <span>
                                        Buscamos las computadoras y notebooks, dispositivos de red e impresoras y realizamos todas las pruebas para darle una nueva vida útil, para las empresa, proyectos o donaciones.
                                    </span>
                                    <br /><br />
                                    <span>
                                        Para aquellos dispositivos que no pueden volver a su funcionamiento, contamos con la estructura para el desarme, división y logística de cada uno de los materiales que los componen.
                                    </span>
                                </div>
                                <div className='column'>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                        <div className='container' style={{width: '100%', height: "100%", padding: "120px 48px", color: '#FFF'}}>
                            <div className='columns'>
                                <div className='column'style={{color: "#FFF", textShadow: '1px 1px 3px black'}}>
                                    <span className='title has-text-white'>
                                        Infraestructura de Red
                                    </span>
                                    <br /><br />
                                    <span>
                                        Preparamos infraestructura nueva o mejoramos la que tu empresa ya tiene para escalar y mejorar, y proveer mejor servicio para tus usuarios y clientes.
                                    </span> 
                                    <br /><br />
                                    <span>
                                        Configuramos servidores, máquinas virtuales, contenedores y servicios de backup para siempre mantener en funcionamiento la compañia.
                                    </span>
                                </div>
                                <div className='column'>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                        <div className='container' style={{width: '100%', height: "100%", padding: "120px 48px", color: '#FFF'}}>
                            <div className='columns'>
                                <div className='column'style={{color: "#FFF", textShadow: '1px 1px 3px black'}}>
                                    <span className='title has-text-white'>
                                        Preparación y venta de equipamiento
                                    </span>
                                    <br /><br />
                                    <span>
                                        Disponemos de equipos reacondicionados y nuevos para satisfacer las necesidades de tu empresa.
                                    </span> 
                                    <br /><br />
                                    <span>
                                    Contactános por Whatsapp o nuestro formulario de contacto.
                                    </span> 
                                </div>
                                <div className='column'>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </Slide>
            <div className="is-hidden-tablet has-text-centered" style={{height:"auto", marginTop: '64px', width: '100%', padding: '32px 32px 68px 32px'}}>
                <span className='title'>
                    Servicios Informáticos
                </span>
                <br /><br />
                                    <span className="subtitle">
                                        Nos dedicamos a brindar una mesa de servicios para empresas e individuos para solucionar sus problemas con equipos, redes, servidores o servicios.
                                    </span>

                                    <br /><br />
                                    <span>
                                    Contactános por Whatsapp o nuestro formulario de contacto.
                                    </span> 
                                    <br /><br />
                                    <span className="is-size-5 has-text-weight-bold">Ver más&nbsp;</span>
                                    <span className="icon" style={{fontSize: '20px'}}>
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </span>
                                
            </div>
        </React.Fragment>
    );
}

export { SectionSlider };