import React from "react";

import { Link } from "react-router-dom";

function Footer(props){
    return(
        <React.Fragment>
            <footer className="footer">
                <div className='container'>
                    <div className="content is-size-7">
                        <div className='columns'>
                            <div className='column'>
                                <p>
                                    <span className='is-size-5 has-text-weight-semibold'>Sitio</span> 
                                    <br />
                                    <Link to={'/'} className=" footer-link">Inicio</Link><br />
                                    <Link to={'/services'} className=" footer-link">Servicios</Link><br />
                                    <a href="/#ourClients" className=" footer-link">Nuestros clientes</a>
                                    <Link to={'/contact'} className=" footer-link">Contacto</Link><br />
                                    <a href="https://instagram.com/ms.i.t" className=" footer-link" target="_blank">Instagram</a>
                                </p>
                            </div>
                            <div className='column'>
                                <p>
                                    <span className='is-size-5 has-text-weight-semibold'>Enlaces</span> 
                                    <br />
                                    <a href="https://st.mesadeserviciosit.com" className=" footer-link" target="_blank">Órdenes de trabajo</a><br />
                                    <a href="https://soporte.mesadeserviciosit.com" className=" footer-link" target="_blank">Sistema de Tickets</a><br />
                                    <a href="https://correo.mesadeserviciosit.com" className=" footer-link" target="_blank">Webmail</a><br />
                                    <Link to={'/downloads'} className=" footer-link">Sitio de Descargas</Link><br />
                                </p>
                            </div>
                            <div className='column has-text-right-tablet has-text-centered-mobile'>
                                <Link to={"/bankdata"} className="button">
                                    <span class="icon is-right">
                                        <i class="fa-solid fa-building-columns"></i>
                                    </span>
                                    <span>Datos Bancarios</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
  
            </footer>
            <div className='end-of-footer'>
                <div className='container has-text-right-tablet has-text-centered-mobile is-size-7'>
                    &#xa9; MSIT {new Date().getFullYear()}
                </div>
            </div>
        </React.Fragment>
    );
}

export { Footer };