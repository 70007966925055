import React from "react";

import { Layout } from "../../layout";

function DescargasPage(props){

    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Mesa de Servicios IT - Descargas";
    }, []);

    return(
        <Layout>
            Descargas
        </Layout>
    );
}

export { DescargasPage };